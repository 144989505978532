import BillItem from './BillItem';

function BillsList({ bills, handleDelete }) {
    if (!bills || bills.length === 0) {
        return <div className='py-5'>No Bills!</div>;
    }

    return (
        <div className='space-y-5 mt-5 pb-5'>
            <h3 className='text-lg font-medium leading-6 text-gray-900'>
                Bills
            </h3>
            {bills.map((item) => {
                return <BillItem key={item.id} item={item} handleDelete={(id) => handleDelete(id) } />;
            })}
        </div>
    );
}

export default BillsList;
