import { toCurrency } from '../util';

function Results({ totalIn, totalOut }) {
    const stats = [
        {
            name: 'Total In',
            stat: toCurrency(totalIn),
        },
        {
            name: 'Total Out',
            stat: toCurrency(totalOut),
        },
        {
            name: 'Remaining',
            stat: toCurrency(totalIn - totalOut),
        },
    ];
    return (
        <div>
            <div>
                <h3 className='text-lg font-medium leading-6 text-gray-900'>
                    Results
                </h3>
                <dl className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3'>
                    {stats.map((item) => (
                        <div
                            key={item.name}
                            className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'
                        >
                            <dt className='truncate text-sm font-medium text-gray-500'>
                                {item.name}
                            </dt>
                            <dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>
                                {item.stat}
                            </dd>
                        </div>
                    ))}
                </dl>
                <div className='text-sm mt-5 text-right text-gray-700'>
                    <span>Created by </span>
                    <a
                        className='font-bold text-indigo-500'
                        href='https://juanrangel.com'
                        target='_blank'
                        rel='noreferrer'
                    >
                        Juan Rangel
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Results;
