import { useState } from 'react';

function Income({ updateIncome }) {
    const handleIncome = (e) => {
        e.preventDefault();

        updateIncome(e.target.value);
    };
    return (
        <div className='mb-5'>
            <div>
                <label
                    htmlFor='income'
                    className='block text-sm font-medium text-gray-700'
                >
                    Income
                </label>
                <div className='relative mt-1 rounded-md shadow-sm'>
                    <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                        <span className='text-gray-500 sm:text-sm'>$</span>
                    </div>
                    <input
                        onChange={handleIncome}
                        type='text'
                        name='income'
                        id='income'
                        className='block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                        placeholder='0.00'
                        aria-describedby='income-currency'
                    />
                    <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                        <span
                            className='text-gray-500 sm:text-sm'
                            id='income-currency'
                        >
                            USD
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Income;
