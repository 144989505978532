import React, { useRef, useState } from 'react';

function BillCreator({ handleAdd }) {
    const [title, setTitle] = useState('');
    const [amount, setAmount] = useState('');
    const titleInput = useRef();

    const handleSubmit = (event) => {
        event.preventDefault();

        const newBill = {
            title,
            amount,
        };

        handleAdd(newBill);
        setTitle('');
        setAmount('');
        titleInput.current.focus();
    };

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };

    return (
        <form action='' onSubmit={handleSubmit}>
            <div className='mb-5 flex justify-evenly space-x-5'>
                <div className='w-full'>
                    <label
                        htmlFor='title'
                        className='block text-sm font-medium text-gray-700'
                    >
                        Title
                    </label>
                    <div className='mt-1'>
                        <input
                            onChange={handleTitleChange}
                            value={title}
                            ref={titleInput}
                            type='text'
                            name='title'
                            id='title'
                            className='block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500'
                            placeholder='Rent'
                            required
                        />
                    </div>
                </div>

                <div className='w-full'>
                    <label
                        htmlFor='amount'
                        className='block text-sm font-medium text-gray-700'
                    >
                        Amount
                    </label>

                    <div className='relative mt-1 rounded-md shadow-sm'>
                        <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                            <span className='text-gray-500 sm:text-sm'>$</span>
                        </div>
                        <input
                            onChange={handleAmountChange}
                            value={amount}
                            type='number'
                            name='income'
                            id='income'
                            className='block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500'
                            placeholder='0.00'
                            aria-describedby='income-currency'
                            required
                        />
                        <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                            <span
                                className='text-gray-500 sm:text-sm'
                                id='income-currency'
                            >
                                USD
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <footer className='flex justify-end'>
                <button className='bg-indigo-500 py-2 w-24  text-white rounded-md transition hover:bg-indigo-600'>
                    Save
                </button>
            </footer>
        </form>
    );
}

export default BillCreator;
