import { XMarkIcon } from '@heroicons/react/24/outline';
function BillItem({ item, handleDelete }) {
    return (
        <div className='bg-white p-3 rounded-md flex items-center shadow justify-between relative'>
            <span className='flex-grow'>{item.title}</span>
            <span className='text-gray-600 text-2xl'>${item.amount}</span>
            <button
                type='button'
                className='absolute -right-2 -top-2 bg-red-500 text-white rounded-full'
                onClick={() => handleDelete(item.id)}
            >
                <XMarkIcon className='w-4 h-4 text-white' />
            </button>
        </div>
    );
}

export default BillItem;
