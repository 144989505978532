import { useState } from 'react';
import './App.css';
import BillCreator from './components/BillCreator';
import BillsList from './components/BillsList';
import BillData from './data/BillData';
import { v4 as uuidv4 } from 'uuid';
import Income from './components/Income';
import Results from './components/Results';

function App() {
    const [bills, setBills] = useState([]);
    const [income, setIncome] = useState(0);

    const addBill = (newBill) => {
        newBill.id = uuidv4();
        setBills([...bills, newBill]);
    };

    const updateIncome = (newIncome) => {
        setIncome(newIncome);
    };

    const getTotalOut = () => {
        let totalOut = 0;

        bills.map((item) => {
            totalOut += parseInt(item.amount);
        });

        return totalOut;
    };

    const deleteBill = (id) => {
        setBills(bills.filter((item) => item.id !== id));
    };

    return (
        <div className='max-w-2xl mx-auto py-10 px-5 sm:px-0'>
            <div className='md:flex md:items-center md:justify-between mb-5'>
                <div className='min-w-0 flex-1'>
                    <h2 className='text-2xl font-bold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight'>
                        Paycheck/Bill Calculator
                    </h2>
                </div>
            </div>

            <Income updateIncome={updateIncome} />
            <BillCreator handleAdd={addBill} />
            <BillsList bills={bills} handleDelete={deleteBill} />
            <Results totalIn={income} totalOut={getTotalOut()} />
        </div>
    );
}

export default App;
